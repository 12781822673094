<template>
  <div class="fill-height">
    <div class="py-2 px-4 elevation-2 page-error-bar" v-if="showError">
      <v-icon color="white">mdi-alert-outline</v-icon>
      <span class="white--text pl-3">{{ error }}</span>
    </div>
    <v-container fluid class="container-main">
      <v-row justify="start">
        <v-col cols="12" class="pb-1">
          <h2>Active Resumes</h2>
          <p class="text-body-1 font-italic text--secondary mb-0">
            Resumes ready for review: {{ reviewCount }}
          </p>
        </v-col>
        <v-col cols="12">
          <v-system-bar color="accent" height="5"></v-system-bar>
          <div class="d-flex">
            <v-text-field
              class="pb-2"
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </div>
          <v-data-table
            :headers="resumeHeaders"
            :items="resumes"
            dense
            :search="search"
            :items-per-page="25"
            @click:row="handleReview"
            class="selectable"
            :footer-props="{ itemsPerPageOptions: [25, 50, 100] }"
          >
            <template v-slot:item.note="{ item }">
              <div class="scroll-multiline my-1">
                <p
                  class="mb-0"
                  v-for="(notei, i) in item.note"
                  :key="notei + i"
                >
                  {{ notei }}
                </p>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import * as disp from "../../utility/display.js";
import * as dUtil from "../../utility/dateutil";

// @ is an alias to /src
export default {
  name: "SubmittedResumes",
  data: () => ({
    showPast: false,
    search: "",
    resumes: [],
    error: "",
  }),
  components: {},
  mounted() {
    this.buildResumeList();
    this.loadActiveReviews().then(this.buildResumeList);
  },
  watch: {
    showPast: {
      handler() {
        this.buildResumeList();
      },
      immediate: false,
    },
  },
  computed: {
    ...mapFields("environment", ["activeReviews"]),
    ...mapFields("currentReview", ["loadResumeError"]),
    showError() {
      return !disp.IsNullorWhitespace(this.error);
    },
    reviewCount() {
      return (
        this.activeReviews?.filter((r) => r.status === "Submitted")?.length ?? 0
      );
    },
    resumeHeaders() {
      return [
        { text: "Submitted By", sortable: true, value: "userDisplay" },
        { text: "Submission Name", sortable: false, value: "name" },
        { text: "Note", sortable: false, value: "note" },
        { text: "Date Submitted", sortable: true, value: "dateSubmitted" },
        { text: "Last Updated", sortable: true, value: "dateUpdated" },
      ];
    },
  },
  methods: {
    ...mapActions("currentReview", ["loadReviewAdmin"]),
    ...mapActions("environment", ["loadActiveReviews"]),
    buildNoteArr(note) {
      if (note == null || note == "") {
        return [];
      }
      return note.split("\n");
    },
    buildResumeList() {
      let tempList = [];
      this.activeReviews.forEach((r) => {
        if (r.status.toLowerCase() === "submitted") {
          const newR = {
            userDisplay: r.userDisplay,
            name: r.name,
            note: this.buildNoteArr(r.note),
            dateSubmitted: dUtil.dateStringToFormat(r.dateSubmitted),
            dateSubmittedSort: r.dateSubmitted,
            dateUpdated: dUtil.dateStringToFormat(r.dateUpdated),
            status: r.status,
            userId: r.userId,
            id: r.id,
          };
          tempList.push(newR);
        }
      });

      tempList.sort((a, b) => {
        try {
          const dtA = new Date(a.dateSubmittedSort);
          const dtB = new Date(b.dateSubmittedSort);
          return dtA.getTime() - dtB.getTime();
        } catch {
          return 0;
        }
      });
      this.resumes = tempList;
    },
    getLength(resumes) {
      if (resumes?.length > 0) {
        return `(${resumes.length})`;
      }
      return "";
    },
    async handleReview(value) {
      await this.loadReviewAdmin({ userId: value.userId, reviewId: value.id });
      if (!disp.IsNullorWhitespace(this.loadResumeError)) {
        this.error = this.loadResumeError;
      } else {
        this.nav(`/ResumeReviewWriter/${value.userId}/${value.id}`);
      }
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
  },
};
</script>

<style>
.selectable tbody tr {
  cursor: pointer;
}
</style>